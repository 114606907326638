import React from 'react';
import PropTypes from 'prop-types';
import ProductSearch from '@components/ProductSearch';
import { DEFAULT_COLLECTION_LABEL } from '../../common';

const ResourcesPage = ({ location }) => (
  <ProductSearch
    location={location}
    defaultPath="/resources"
    secondaryDefaultLabel={DEFAULT_COLLECTION_LABEL}
    advancedFiltersOpen
    pageName=" | Sort by Resource"
  />
);

ResourcesPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default ResourcesPage;
